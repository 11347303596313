import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { Store } from '@ngrx/store';

import { filter, take } from 'rxjs/operators';
import { AppState } from '../store/states/app.state';
import { selectUserModules } from '../store/selectors/auth.selectors';

@Directive({
  selector: '[hasModule]',
  standalone: true,
})
export class UserHasModuleDirective {
  private module!: string;

  @Input() set hasModule(module: string) {
    this.module = module;
    this.updateView();
  }

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store: Store<AppState>) {}

  private updateView(): void {
    if (this.module === '*') {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.store
      .select(selectUserModules)
      .pipe(
        filter((feat) => !!feat),
        take(1)
      )
      .subscribe({
        next: (modules) => {
          if (JSON.stringify(modules)?.toLowerCase().includes(this.module.toLowerCase())) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        },
      });
  }
}
