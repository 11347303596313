import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Store} from '@ngrx/store';

import {switchMap, take} from 'rxjs/operators';
import { AppState } from 'src/app/store/states/app.state';
import { selectCompanyTenant } from 'src/app/store/selectors/auth.selectors';
import { CURRENT_TENANT } from 'src/app/models/core/tenant.model';


@Injectable()
export class TenantInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectCompanyTenant()).pipe(
      take(1),
      switchMap(tenant => {
          if (tenant.company && tenant.activeTenant) {
            const newRequest = req.clone({
              url: req.url.replace(CURRENT_TENANT, tenant?.activeTenant?.name),
              headers: req.headers.set('x-company', tenant?.company?.name || '')
            });
            return next.handle(newRequest);
          }
          return next.handle(req);
        }
      )
    );
  }
}
