<main [ngClass]="{ 'light': !isDarkTheme, 'dark': isDarkTheme }" cubeNotificationHandler cubeTranslate>
  <mat-sidenav-container fullscreen class="cube-container">
    <mat-sidenav [mode]="sideNavMode" class="sidenav" #sidenav [opened]="sideNavOpened" style="overflow: hidden">
      <mat-toolbar color="primary" class="bg-logo mat-elevation-z4 {{ logoBg }}">
        <img src="{{ expandedLogo }}" height="42px" class="flex-around-center" />
        <h1 class="logo" [innerHTML]="companyName"></h1>
        <a routerLink="/" class="home"></a>
      </mat-toolbar>
      <cube-sidemenu [features]="features" [featuresStore]="features$ | async"/>
    </mat-sidenav>
    <mat-sidenav-content style="z-index: unset; overflow: hidden">
      <mat-drawer-container fullscreen>
        <mat-drawer autoFocus="false" mode="side" #drawer class="drawer" [opened]="matDrawerOpened" style="overflow: hidden">
          <mat-toolbar color="primary" class="bg-logo mat-elevation-z4 {{ logoBg }}">
            <img src="{{ logo }}" width="36px" />
            <a routerLink="/" class="home"></a>
          </mat-toolbar>
          <cube-sidemenu [features]="features" [iconOnly]="true" [featuresStore]="features$ | async"/>
        </mat-drawer>
        <mat-drawer-content style="overflow: hidden">
          <cube-toolbar [allAvailableTenants]="allAvailableTenants$ | async" [availableTenants]="availableTenants$ | async" [user]="user$ | async" [currentTenant]="currentTenant$ | async" [activeTenant]="activeTenant$ | async" [showDrawer]="matDrawerShow" (togglePanels)="sidenav.toggle(); drawer.toggle()" (changeActiveTenant)="changeActiveTenant($event)" (changeCurrentTenant)="changeCurrentTenant($event)"  (switchTheme)="switchTheme()"
            (toggleSidenav)="sidenav.toggle()" (logout)="logout()" />
          <div class="cube-toolbar-ghost"></div>
          <div class="router-container">
            <router-outlet></router-outlet>
          </div>
        </mat-drawer-content>
      </mat-drawer-container>
    </mat-sidenav-content>
  </mat-sidenav-container>

</main>

@if(loading$ | async){
<cube-spinner class="flex-center overlay-page" />
}
