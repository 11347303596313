import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from '../models/user';
import { Neighbours } from 'src/app/models/core/link.model';
import { B2CUser } from '../models/b2c-user.type';

export const UsersActions = createActionGroup({
  source: 'Users Actions',
  events: {
    UsersReset: emptyProps(),
    FetchUsers: emptyProps(),
    FetchUsersSuccess: props<{ users: Array<User> }>(),
    FetchUsersFail: props<{ error: Error }>(),
    AddUser: props<{ user: User }>(),
    AddUserSuccess: props<{ user: User }>(),
    AddUserFail: props<{ error: Error }>(),
    UpdateUser: props<{ userName: string; user: User }>(),
    UpdateUserSuccess: props<{ userName: string; user: User }>(),
    UpdateUserFail: props<{ error: Error }>(),
    DeleteUser: props<{ user: User }>(),
    DeleteUserSuccess: props<{ user: User }>(),
    DeleteUserFail: props<{ error: Error }>(),
    GetUsersCompositeProperties: props<{ userId: string; tenant: string }>(),
    GetUsersCompositePropertiesSuccess: props<{ neighbours: Neighbours }>(),
    GetUsersCompositePropertiesFail: props<{ error: Error }>(),
    GetUserById: props<{ userId: string }>(),
    GetUserByIdSuccess: props<{ userById: User }>(),
    GetUserByIdFail: props<{ error: Error }>(),
    GetUsersNeighboursProperties: props<{ userId: string }>(),
    GetUsersNeighboursPropertiesSuccess: props<{ users: Neighbours }>(),
    GetUsersNeighboursPropertiesFail: props<{ error: Error }>(),
    PostNodesLinkSuccess: props<{ keyNode: string; node: any }>(),
    DeleteNodesLinkSuccess: props<{ keyNode: string; node: any }>(),
    B2cLookUp: props<{ filter: string }>(),
    B2cLookUpSuccess: props<{ users: Array<any> }>(), //da togliere any
    B2cLookUpFail: props<{ error: Error }>(),
    SelectB2CUser: props<{ user: B2CUser }>(),
  },
});
