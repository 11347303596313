import { createReducer, on } from '@ngrx/store';
import { Profile, User } from 'src/app/models/auth/admin-tool.type';
import { TokenDetails } from 'src/app/models/auth/token-details.type';
import { Tenant } from 'src/app/models/core/tenant.model';
import { AuthActions } from '../actions/auth.actions';
import { AuthState } from '../states/auth.state';


const INIT_STATE: AuthState = {
  tokenDetails: {} as TokenDetails,
  user: {} as User,
  tenants: new Array<Tenant>(),
  allAvailableTenants: new Array<Tenant>(),
  tenant: {name: 'root'} as Tenant,
  activeTenant: {} as Tenant,
  roles: undefined
};

export const authReducers = createReducer(
  INIT_STATE,
  on(AuthActions.setTokenDetails, (state: AuthState, { tokenDetails }): AuthState => {
    const { name: first_name, family_name: last_name, sub: username } = (tokenDetails as any);
    const user: User = { profile: { first_name, last_name, username } as Profile } as User;
    const auth = { tokenDetails, user };
    return { ...state, ...auth };
  }),
  on(AuthActions.logout, (): AuthState => ({ ...INIT_STATE })),
  on(AuthActions.fetchUserTenantsSuccess, (state: AuthState, { tenants }): AuthState => ({ ...state, tenants })),
  on(AuthActions.updateTenantInUse, (state: AuthState, { tenant }): AuthState => ({ ...state, tenant, allAvailableTenants: [] })),
  on(AuthActions.switchTenantInUse, (state: AuthState, { tenant }): AuthState => ({ ...state, tenant, allAvailableTenants: []})),
  on(AuthActions.fetchUserFeaturesSuccess, (state: AuthState, { roles }): AuthState => ({ ...state, roles})),
  on(AuthActions.resetUserFeatures, (state: AuthState): AuthState => ({ ...state, roles: undefined })),
  on(AuthActions.getAllAvailableTenantsSuccess, (state: AuthState, { tenants }): AuthState => ({ ...state, allAvailableTenants: tenants })),
  on(AuthActions.changeActiveTenant, (state: AuthState, { tenant }): AuthState => ({ ...state, activeTenant: tenant})),
  );
