export const b2cPolicies = {
  clientId: 'a65fb662-2098-44d3-9f53-2e8d72881efc',
  tenantId: 'c7788286-49f4-40db-bf26-fc1c677df787',
  tenant: 'rinagroupb2cdevl',
  redirectCourtesyPageUri: 'https://www.rina.org/',
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
    resetPassword: 'B2C_1A_PASSWORDRESET',
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
    },
    resetPassword: {
      authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PASSWORDRESET',
    },
    editProfile: {
      authority: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/B2C_1A_PROFILEEDIT',
    },
  },
  domain: 'https://test-login.rina.org/',
  subdomain: 'rinagroupb2cdevl.onmicrosoft.com',
  authorityDomain: 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com',
};