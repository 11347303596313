import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { ManagerApiService } from 'src/app/providers/services/manager.api.service';
import { ManagerActions } from '../actions/manager.actions';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { UsersActions } from 'src/app/features/users/store/users.actions';
import { RolesActions } from 'src/app/features/roles/store/roles.actions';
import { UserGroupsActions } from 'src/app/features/user-groups/store/user-groups.actions';
import { AssetsActions } from 'src/app/features/assets/store/assets.actions';
import { AssetGroupsActions } from 'src/app/features/asset-groups/store/asset-groups.actions';
import { CURRENT_TENANT } from 'src/app/models/core/tenant.model';
import { MessageType } from 'src/app/models/core/system-notification.interface';
import { SystemActions } from '../actions/system.actions';

@Injectable()
export class ManagerEffects {
  readonly #managerService: ManagerApiService = inject(ManagerApiService);
  readonly #actions: Actions = inject(Actions);

  postNodesLink$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ManagerActions.postNodesLink),
      switchMap(({ linkLabel, object, subject, tenant, keyNode, keyState, node }) =>
        this.#managerService
          .postNodesLink(linkLabel, subject, object, tenant)
          .pipe(
            switchMap(() => [
              ManagerActions.postNodesLinkSuccess({ keyNode, keyState, node }),
              SystemActions.setNotification({ notification: { type: MessageType.Info, message: subject.name + ' created successfully' } }),
            ])
          )
      ),
      catchError((error) => of(ManagerActions.postNodesLinkFail({ error })))
    )
  );

  deleteNodesLink$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ManagerActions.deleteNodesLink),
      switchMap(({ linkLabel, object, subject, tenant, keyNode, keyState, node }) =>
        this.#managerService
          .deleteNodesLink(linkLabel, subject, object, tenant)
          .pipe(
            switchMap(() => [
              ManagerActions.deleteNodesLinkSuccess({ keyNode, keyState, node }),
              SystemActions.setNotification({ notification: { type: MessageType.Info, message: object.name + ' deleteded successfully' } }),
            ])
          )
      ),
      catchError((error) => of(ManagerActions.postNodesLinkFail({ error })))
    )
  );

  getSelectedBySolution$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ManagerActions.getSelectedBySolution),
      switchMap(({ platform, solution, role, typeS }) =>
        this.#managerService.getSelectedBySolution(platform, solution, role, typeS).pipe(map((response: any) => ManagerActions.getSelectedBySolutionSuccess({ response })))
      ),
      catchError((error) => of(ManagerActions.getSelectedBySolutionFail({ error })))
    )
  );

  getNodeProperties$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ManagerActions.getNodeProperties),
      mergeMap(({ configKey, nodeId }) =>
        this.#managerService.getNodeProperties(configKey, nodeId).pipe(map((response: any) => ManagerActions.getNodePropertiesSuccess({ response, configKey })))
      ),
      catchError((error) => of(ManagerActions.getNodePropertiesFail({ error })))
    )
  );

  getTenantNodes$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ManagerActions.getTenantNodes),
      mergeMap(({ node }) => this.#managerService.getTenantNodes(node).pipe(map((response: any) => ManagerActions.getNodePropertiesSuccess({ response, configKey: node })))),
      catchError((error) => of(ManagerActions.getNodePropertiesFail({ error })))
    )
  );

  getTenantResources$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ManagerActions.getTenantResources),
      mergeMap(({ domain }) =>
        this.#managerService.getTenantResources(CURRENT_TENANT, domain).pipe(map((response: any) => ManagerActions.getTenantResourcesSuccess({ response })))
      ),
      catchError((error) => of(ManagerActions.getTenantResourcesFail({ error })))
    )
  );

  postNodesLinkSuccess$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ManagerActions.postNodesLinkSuccess),
      switchMap(({ keyNode, keyState, node }) => {
        let action$;
        switch (keyState) {
          case 'users': {
            action$ = of(UsersActions.postNodesLinkSuccess({ keyNode, node }));
            break;
          }
          case 'roles': {
            action$ = of(RolesActions.postNodesLinkSuccess({ keyNode, node }));
            break;
          }
          case 'userGroups': {
            action$ = of(UserGroupsActions.postNodesLinkSuccess({ keyNode, node }));
            break;
          }
          case 'assets': {
            action$ = of(AssetsActions.postNodesLinkSuccess({ keyNode, node }));
            break;
          }
          case 'assetGroups': {
            action$ = of(AssetGroupsActions.postNodesLinkSuccess({ keyNode, node }));
            break;
          }
          //etc
          default: {
            action$ = of();
            break;
          }
        }
        return action$;
      })
    )
  );

  deleteNodesLinkSuccess$ = createEffect(() =>
    this.#actions.pipe(
      ofType(ManagerActions.deleteNodesLinkSuccess),
      switchMap(({ keyNode, keyState, node }) => {
        let action$;
        switch (keyState) {
          case 'users': {
            action$ = of(UsersActions.deleteNodesLinkSuccess({ keyNode, node }));
            break;
          }
          case 'roles': {
            action$ = of(RolesActions.deleteNodesLinkSuccess({ keyNode, node }));
            break;
          }
          case 'userGroups': {
            action$ = of(UserGroupsActions.deleteNodesLinkSuccess({ keyNode, node }));
            break;
          }
          case 'assets': {
            action$ = of(AssetsActions.deleteNodesLinkSuccess({ keyNode, node }));
            break;
          }
          case 'assetGroups': {
            action$ = of(AssetGroupsActions.deleteNodesLinkSuccess({ keyNode, node }));
            break;
          }
          //etc
          default: {
            action$ = of();
            break;
          }
        }
        return action$;
      })
    )
  );
}
