import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PlatformFeature } from 'src/app/models/core/feature.model';
import { LinkLabels, LinkLabelsSubject, LinkLabelsObject } from 'src/app/models/core/link-labels';
import { CURRENT_TENANT } from 'src/app/models/core/tenant.model';
import { selectActiveTenant } from 'src/app/store/selectors/auth.selectors';
import { AppState } from 'src/app/store/states/app.state';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ManagerApiService {
  readonly #http: HttpClient = inject(HttpClient);
  readonly #store: Store<AppState> = inject(Store<AppState>);

  activeTenant: string | undefined;

  constructor() {
    this.#store
      .select(selectActiveTenant)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (activeTenant) => (this.activeTenant = activeTenant || undefined),
      });
  }

  getSelectedBySolution(platform: string, solution: string, role: string, typeS: 'feature' | string): Observable<any[]> {
    return this.#http.get<any[]>(
      `${environment.api.baseUrl}/tenants/${this.activeTenant ? this.activeTenant : CURRENT_TENANT}/roles/${role}/platforms/${platform}/solutions/${solution}/${typeS}`
    );
  }

  getTenantFeatures(tenant = CURRENT_TENANT): Observable<PlatformFeature[]> {
    return this.#http.get<PlatformFeature[]>(`${environment.api.baseUrl}/tenants/${this.activeTenant ? this.activeTenant : tenant}/features`);
  }

  // --- Platform API ---

  getTenantNodes(node: string, tenant = CURRENT_TENANT): Observable<any> {
    return this.#http.get<any>(`${environment.api.baseUrl}/tenants/${this.activeTenant ? this.activeTenant : tenant}/${node}`);
  }

  postNodesLink(linkLabel: LinkLabels | string, subject: LinkLabelsSubject, object: LinkLabelsObject, tenant = CURRENT_TENANT): Observable<unknown> {
    return this.#http.post<unknown>(`${environment.api.baseUrl}/tenants/${this.activeTenant ? this.activeTenant : tenant}/links/${linkLabel}/${object.name}/${subject.name}`, {});
  }

  deleteNodesLink(linkLabel: string, subject: LinkLabelsSubject, object: LinkLabelsObject, tenant = CURRENT_TENANT): Observable<unknown> {
    return this.#http.delete<unknown>(`${environment.api.baseUrl}/tenants/${this.activeTenant ? this.activeTenant : tenant}/links/${linkLabel}/${subject.name}/${object.name}`);
  }

  getNodeProperties(nodeLabel: string, nodeId?: string, tenant = CURRENT_TENANT): Observable<any> {
    return this.#http.get<any>(`${environment.api.baseUrl}/tenants/${this.activeTenant ? this.activeTenant : tenant}/nodes/${nodeLabel}${nodeId ? '/' + nodeId : ''}`);
  }

  getTenantResources(tenant = CURRENT_TENANT, domain?: Array<string>): Observable<any> {
    return this.#http.get<any>(
      `${environment.api.baseUrl}/tenants/${this.activeTenant ? this.activeTenant : tenant}/resources?${domain?.length ? 'domains=' + domain?.join(',') : ''}`
    );
  }
}
