/* eslint-disable @typescript-eslint/no-explicit-any */
import { Type } from "@angular/core";
import { ActionReducer, ActionReducerMap, MetaReducer, RuntimeChecks } from "@ngrx/store";
import { StoreDevtoolsConfig } from "@ngrx/store-devtools";
import { environment } from "src/environments/environment";
import { AuthEffects } from "./effects/auth.effects";
import { SystemEffects } from "./effects/system.effects";

import { systemReducers } from "./reducers/system.reducers";
import { AppState } from "./states/app.state";
import { localStorageSync, LocalStorageConfig } from "ngrx-store-localstorage";
import { ManagerEffects } from "./effects/manager.effects";
import { managerReducers } from "./reducers/manager.reducers";
import { authReducers } from "./reducers/auth.reducers";

export const reducers: ActionReducerMap<AppState> = {
	auth: authReducers,
	system: systemReducers,
	manager: managerReducers
};

export const effects: Array<Type<unknown>> = [
	AuthEffects,
	SystemEffects,
	ManagerEffects
];

export const runtimeChecks: RuntimeChecks = {
	strictActionImmutability: true,
	strictActionSerializability: true,
	strictActionWithinNgZone: true,
	strictStateImmutability: true,
	strictStateSerializability: true,
	strictActionTypeUniqueness: true
};

export const devtoolsConfig: StoreDevtoolsConfig = {
  maxAge: 25,
  logOnly: environment.production
};

function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync(localStorageConfig)(reducer);
}

const localStorageConfig: LocalStorageConfig = {
  keys: ['auth', 'system'],
  rehydrate: true,
  storageKeySerializer: (key: string) => `${environment.localStorageKeyPrefix}_${key}`,
};

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];