@if(user){
<div class="toolbar-user-container">
  <button mat-button [matMenuTriggerFor]="content" class="toolbar-user-btn">
    <div class="toolbar-user-btn-content">
      <span class="avatarContainer"><img alt="avatar" class="avatar" src="./assets/img/profile.jpg" /></span>
      <div class="name d-flex">
        <span>{{ user.profile.first_name }}</span>
        <cite>{{ ((currentTenant | uppercase) || "") + (activeTenant ? " ( " + activeTenant + " ) " : "") }}</cite>
      </div>
    </div>
  </button>
  <div class="user-menu mat-elevation-z1" [class.open]="isOpen">
    <mat-menu class="content" #content="matMenu">
      <button mat-menu-item (click)="support.emit()">
        <mat-icon>headset_mic</mat-icon>
        <a matLine>Support</a>
      </button>
      <button mat-menu-item matLine [matMenuTriggerFor]="tenants">
        <mat-icon>language</mat-icon>
        <a>Change Tenant</a>
      </button>
      @if(allAvailableTenants && allAvailableTenants.length>0 ){
      <button mat-menu-item matLine [matMenuTriggerFor]="allTenants">
        <mat-icon>language</mat-icon>
        <a>Change Active Tenant</a>
      </button>
      }
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="switchTheme.emit()">
        <mat-icon>invert_colors</mat-icon>
        <a matLine>Change theme</a>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logout.emit()">
        <mat-icon>exit_to_app</mat-icon>
        <a matLine>Logout</a>
      </button>
    </mat-menu>
    <mat-menu #allTenants="matMenu">
      @for(tenant of allAvailableTenants; track $index){
      <button mat-menu-item (click)="changeActiveTenant.emit(tenant)">
        {{ tenant.name }}
      </button>
      }
    </mat-menu>
    <mat-menu #tenants="matMenu">
      @for(tenant of availableTenants; track $index){
      <button mat-menu-item (click)="changeCurrentTenant.emit(tenant)">
        {{ tenant.name }}
      </button>
      }
    </mat-menu>
  </div>
</div>
}
