import { createSelector } from "@ngrx/store";
import { AppState } from "../states/app.state";
import { SystemState } from "../states/system.state";
import { Theme } from "src/app/models/core/layout-state.model";
import { SystemNotification } from "src/app/models/core/system-notification.interface";
import { LanguageOption } from "src/app/models/core/language-option.type";

const systemState = (state: AppState): SystemState => state.system;
export const selectCurrentLang = createSelector(systemState, (state: SystemState): LanguageOption => state.currentLang);
export const selectLoading = createSelector(systemState, (state: SystemState): boolean => state?.loading);
export const selectTheme = createSelector(systemState, (state: SystemState): Theme => state?.theme);
export const selectNotifications = createSelector(systemState, (state: SystemState): SystemNotification | undefined => state?.notification);