import { LOCALE_ID, NgModule } from '@angular/core';
/* import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
 */import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
/* import { MSALInstanceFactory, MSALGuardConfigFactory } from './configs/msal.config';
 */import { AzureTokenInterceptor } from './providers/interceptors/azure-token.interceptor';
import { LoadingInterceptor } from './providers/interceptors/loading.interceptor';
import { NotificationInterceptor } from './providers/interceptors/notification.interceptor';
import { ROUTES } from './app.routes';
import * as store from './store';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoadingSpinnerComponent } from './components/ui-elements/loading-spinner/loading-spinner.component';
//import { CubeMSALFlowDirective } from './directives/msal-flow.directive';
import { BrowserModule } from '@angular/platform-browser';
import { TenantInterceptor } from './providers/interceptors/tenant.interceptor';
import { CubeTranslateDirective } from './directives/translate.directive';
import { TranslateModule } from '@ngx-translate/core';
import * as translate from './configs/translate.config';
import { NotificationHandlerDirective } from './directives/notification-handler.directive';
import { UserHasModuleDirective } from './directives/user-has-module.directive';
import { SidemenuModule } from './components/layout/sidemenu/sidemenu.module';
import { ToolbarComponent } from './components/layout/toolbar/toolbar.component';
import { MaterialModule } from './modules/material.module';
import { SafeHtmlPipe } from './providers/pipes/safe-html.pipe';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    MaterialModule,
    SidemenuModule,
    SafeHtmlPipe,
    ToolbarComponent,
    RouterModule.forRoot(ROUTES, { bindToComponentInputs: true, onSameUrlNavigation: 'reload' }),
    LoadingSpinnerComponent,
    MatSnackBarModule,
    /* CubeMSALFlowDirective, */
    CubeTranslateDirective,
    NotificationHandlerDirective,
    UserHasModuleDirective,
    BrowserModule,
    TranslateModule.forRoot(translate.config),
  ],
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideStore(store.reducers, { metaReducers: store.metaReducers }),
    provideStoreDevtools(store.devtoolsConfig),
    provideEffects(store.effects),
    provideAnimations(),
    { provide: HTTP_INTERCEPTORS, useClass: AzureTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true },
/*     {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    }, */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantInterceptor,
      multi: true,
    },
/*     MsalService,
    MsalGuard,
    MsalBroadcastService, */
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
