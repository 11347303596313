/* eslint-disable @ngrx/avoid-mapping-selectors */
import { Component, inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { delay, filter, map, Observable } from 'rxjs';


import { MatDrawerMode } from '@angular/material/sidenav';
 import { concatLatestFrom } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { SIDE_MENU } from './components/layout/sidemenu/config/sidemenu.config';
import { SideMenuItem } from './components/layout/sidemenu/models/sidemenu-item.interface';
import { selectTenants } from './features/tenants/store';
import { User } from './models/auth/admin-tool.type';
import { TokenDetails } from './models/auth/token-details.type';
import { Theme } from './models/core/layout-state.model';
import { Tenant } from './models/core/tenant.model';
import { AuthenticationService } from './providers/services/authentication.service';
import { TokenService } from './providers/services/token.service';
import { AuthActions } from './store/actions/auth.actions';
import { SystemActions } from './store/actions/system.actions';
import { selectActiveTenant, selectAllAvailableTenants, selectAllTenants, selectCurrentTenant, selectTokenDetails, selectUserDetails, selectUserModules, selectUsername } from './store/selectors/auth.selectors';
import { selectLoading, selectTheme } from './store/selectors/system.selectors';
import { AppState } from './store/states/app.state';
import { jwtDecode } from 'jwt-decode';

@Component({
  selector: 'cube-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy{
  isDarkTheme: boolean = true;
  themeClass: string = 'dark-theme';
  loading$: Observable<boolean>;
  user$: Observable<User | undefined>;
    logo: string = environment.company.logo || './assets/logo.png';
    logoBg: string = environment.company.logoBg || 'bg00598a';
    companyName: string = environment.company.name || 'RINA.<span style="font-size: 12px;">com</span>';
    expandedLogo: string = environment.company.expandedLogo;
  
    sideNavMode: MatDrawerMode = 'side';
    sideNavOpened = false; // T
    matDrawerOpened = true; // F
    matDrawerShow = true; // T
    username$: Observable<string | undefined>;
    activeTenant$!: Observable<string | undefined>;
    currentTenant$!: Observable<string>;
  
    features: Array<SideMenuItem>;
  availableTenants$: Observable<Array<Tenant> | undefined>;
  allAvailableTenants$: Observable<Array<Tenant> | undefined>;
  
    selectedTheme!: Theme;
    features$: Observable<Array<string> | undefined>;
  readonly #store = inject(Store<AppState>);
  readonly #authService = inject(AuthenticationService);
  readonly #tokenService = inject(TokenService);


  constructor() {
    //this.#msal.logoutRedirect()
            this.username$ = this.#store.select(selectUsername);
        this.availableTenants$ = this.#store.select(selectAllTenants);
        this.allAvailableTenants$ = this.#store.select(selectAllAvailableTenants);
        this.currentTenant$ = this.#store.select(selectCurrentTenant);
        this.activeTenant$ = this.#store.select(selectActiveTenant);
        this.#store.dispatch(AuthActions.login());
  
      this.#authService.token.pipe(
        filter((token: string | undefined): token is string => !!token)).subscribe(token => {
          const uniqueId = this.#tokenService.getUniqueId(token);
          this.#store.dispatch(AuthActions.setTokenDetails({ tokenDetails: { ...jwtDecode(token), uniqueId } as TokenDetails }));
        });

        this.#store
          .select(selectTheme)
          .pipe(takeUntilDestroyed())
          .subscribe({
            next: (theme) => (this.selectedTheme = theme),
          });
        this.features = SIDE_MENU;
        this.features$ = this.#store.select(selectUserModules);
    this.loading$ = this.#store.select(selectLoading).pipe(delay(500));
    this.user$ = this.#store.select(selectUserDetails).pipe(filter((user: User | undefined): boolean => !!user));

      this.#store
      .select<Theme>(selectTheme)
      .pipe(
        // eslint-disable-next-line @ngrx/avoid-mapping-selectors
        map((theme: Theme): boolean => theme === 'dark'),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (isDark: boolean) => {
          this.isDarkTheme = isDark;
          const newThemeClass: string = isDark ? 'dark-theme' : 'light-theme';

          const bodyElement = document.body;
          if (bodyElement) {
            bodyElement.classList.remove(this.themeClass);
            bodyElement.classList.add(newThemeClass);
            this.themeClass = newThemeClass;
          }
        },
      });
      this.#store
      .select(selectTokenDetails)
      .pipe(
        filter((tokenDetails: TokenDetails | undefined): tokenDetails is TokenDetails => !!tokenDetails),
        concatLatestFrom(() => this.#store.select(selectAllAvailableTenants)),
        takeUntilDestroyed()
      )
      .subscribe({
        next: ([tokenDetails, tenants]) => {
          if (!tenants.length) {
            this.#store.dispatch(AuthActions.fetchUserTenants({ uniqueId: tokenDetails.uniqueId }));
          }
        },
      });
  }

    ngOnDestroy(): void {
      this.#store.dispatch(AuthActions.resetUserFeatures());
    }
  
    logout(): void {
      this.#store.dispatch(AuthActions.logout());
    }
  
    changeCurrentTenant(tenant: Tenant) {
      this.#store.dispatch(AuthActions.resetUserFeatures());
  
      this.#store.dispatch(AuthActions.switchTenantInUse({ tenant }));
      this.#store.dispatch(AuthActions.changeActiveTenant({ tenant: undefined }));
    }
  
    changeActiveTenant(tenant: Tenant) {
      this.#store.dispatch(AuthActions.changeActiveTenant({ tenant }));
    }
  
    switchTheme() {
      this.#store.dispatch(SystemActions.setTheme({ theme: this.selectedTheme === Theme.LightTheme ? Theme.DarkTheme : Theme.LightTheme }));
    }
}
