import { createReducer, on } from "@ngrx/store";
import { SystemActions } from "../actions/system.actions";
import { SystemState } from "../states/system.state";
import { Theme } from "src/app/models/core/layout-state.model";

const INIT_STATE: SystemState = {
  currentLang: 'en',
  loading: false,
  notification: undefined,
  theme: Theme.DarkTheme
};

export const systemReducers = createReducer(
  INIT_STATE,
  on(SystemActions.setNotification, (state: SystemState, { notification }): SystemState => ({ ...state, notification })),
  on(SystemActions.setTheme, (state: SystemState, { theme }): SystemState => ({ ...state, theme })),
  on(SystemActions.startLoading, (state: SystemState): SystemState => ({ ...state, loading: true })),
  on(SystemActions.stopLoading, (state: SystemState): SystemState => ({ ...state, loading: false })),
)