<mat-toolbar class="flex-between-center mat-elevation-z4">
  <div>
    <button mat-icon-button (click)="toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>
  <div class="w-50 flex-end-center">
    <cube-fullscreen />
  
    <cube-user-menu [activeTenant]="activeTenant" [currentTenant]="currentTenant" [user]="user" [availableTenants]="availableTenants" [allAvailableTenants]="allAvailableTenants" (login)="login.emit()" (logout)="logout.emit()" (support)="openSupportPage()" (changeCurrentTenant)="changeCurrentTenant.emit($event)" (changeActiveTenant)="changeActiveTenant.emit($event)" (switchTheme)="switchTheme.emit()" />
  </div>
</mat-toolbar>
