/* eslint-disable @ngrx/no-typed-global-store */
import { Directive, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';

import { AppState } from '../store/states/app.state';
import { selectNotifications } from '../store/selectors/system.selectors';
import { SystemActions } from '../store/actions/system.actions';
import { SystemNotification, MessageType } from '../models/core/system-notification.interface';
import { environment } from 'src/environments/environment';

@Directive({ selector: '[cubeNotificationHandler]', standalone: true })
export class NotificationHandlerDirective {
  readonly #store: Store<AppState> = inject(Store<AppState>);
  readonly router: Router = inject(Router);
  readonly snackbar: MatSnackBar = inject(MatSnackBar);

  constructor() {
    this.#store
      .select(selectNotifications)
      .pipe(
        filter((notification: SystemNotification | undefined): notification is SystemNotification => !!notification),
        takeUntilDestroyed()
      )
      .subscribe({
        next: (notification: SystemNotification) => this.openNotificationSnackbar(notification),
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed()
      )
      .subscribe({
        next: () => {
          this.#store.dispatch(SystemActions.setNotification({ notification: undefined }));
          this.snackbar.dismiss();
        },
      });
  }

  openNotificationSnackbar({ type, message }: SystemNotification): void {
    const snackBarConfig = this.snackBarConfig(type);
    this.snackbar.open(message, 'close', snackBarConfig);
  }

  snackBarConfig(type: MessageType): MatSnackBarConfig {
    const snackBarConfig = new MatSnackBarConfig();
    const panelClass = ['snackBarMessage'];

    switch (type) {
      case MessageType.Info:
        snackBarConfig.duration = environment.snackbar_config.snackbar_duration;
        snackBarConfig.panelClass = [...panelClass, 'infoMessage'];
        break;
      case MessageType.Error:
        snackBarConfig.duration = environment.snackbar_config.snackbar_error_duration;
        snackBarConfig.panelClass = [...panelClass, 'errorMessage'];
        break;
    }

    return snackBarConfig;
  }
}
