import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';

import { AuthenticationService } from 'src/app/providers/services/authentication.service';
import { environment } from 'src/environments/environment';
import { AuthActions } from '../actions/auth.actions';

import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { UsersActions } from 'src/app/features/users/store/users.actions';
import { TokenDetails } from 'src/app/models/auth/token-details.type';
import { Tenant } from 'src/app/models/core/tenant.model';

@Injectable()
export class AuthEffects {
  readonly #authService: AuthenticationService = inject(AuthenticationService);
  readonly #actions$: Actions = inject(Actions);
  readonly #auth: AuthenticationService = inject(AuthenticationService);
  readonly #route: Router = inject(Router);

/*   setActiveAccount$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(AuthActions.setActiveAccount),
        tap(() => this.#authService.checkAndSetActiveAccount())
      );
    },
    { dispatch: false }
  ); */

  logout$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => this.#authService.logout())
      );
    },
    { dispatch: false }
  );

/*   redirectSuccess$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(AuthActions.setTokenDetails),
      switchMap(() => this.#auth.whoAmI().pipe(map((response: any) => AuthActions.fetchUserTenantsSuccess({ tenants: response.tenants.app })))),
      catchError((error) => of(AuthActions.fetchUserTenantsFail(error)))
    )
  ); */

  userTenants$ = createEffect(() => {
    return this.#actions$.pipe(
      ofType(AuthActions.fetchUserTenants),
      switchMap(({ uniqueId }) =>
        this.#authService.whoAmI().pipe(
          map((d:any)=> d.tenants.app),
          switchMap((tenants: Array<Tenant>) => {
            if (tenants.length) {
              const current_tenant: string | null = localStorage.getItem(`${environment.localStorageKeyPrefix}_tenant`);
              const active_tenant: Tenant = current_tenant ? { name: current_tenant, info: '' } : tenants[0];
              return [AuthActions.fetchUserTenantsSuccess({ tenants })];
            } else {
              return [AuthActions.setTokenDetails({ tokenDetails: <TokenDetails>{} })];
            }
          }),
          catchError((error: HttpErrorResponse) => of(AuthActions.fetchUserTenantsFail({ error })))
        )
      )
    );
  });
  login$ = createEffect(
    () => {
      return this.#actions$.pipe(
        ofType(AuthActions.login),
        tap(() => this.#authService.login())
      
      );
    },
    { dispatch: false }
   
  );

  fetchUserTenant$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(AuthActions.fetchUserTenantsSuccess),
      switchMap(({ tenants }) => {
        const { companyTenant, localStorageKeyPrefix } = environment;
        let authLocal = localStorage.getItem(localStorageKeyPrefix + '_auth');
        let tenantName: string;
        if (authLocal) {
          const auth = JSON.parse(authLocal);
          tenantName = auth?.tenant?.name;
        }

        return [
          AuthActions.canIUse(),
          AuthActions.updateTenantInUse({
            tenant: tenants.find((t) => (authLocal ? t.name === tenantName : t.name === companyTenant.name)) || tenants[0],
          }),
        ];
      }),
      catchError((error) => of(AuthActions.fetchUserTenantsFail(error)))
    )
  );
  updatUserTenant$ = createEffect(
    () =>
      this.#actions$.pipe(
        ofType(AuthActions.switchTenantInUse),
        tap(() => {
          window.location.reload();
        })
      ),
    { dispatch: false }
  );
  changeActibeTenant$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(AuthActions.changeActiveTenant),
      switchMap(({ tenant }) => {
        if (tenant) {
          const currentUrl = this.#route.url;
          if (currentUrl === '/users') {
            return [UsersActions.fetchUsers()];
          } else {
            this.#route.navigate(['/users']);
            return [];
          }
        }
        return [];
      })
    )
  );

  fetchUserFeatures$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(AuthActions.canIUse),
      switchMap(() =>
        this.#auth.canIuse().pipe(
          switchMap((roles) => {
            let actions = [];
            roles.modules.includes('admin-tool.Business')
              ? (actions = [AuthActions.fetchUserFeaturesSuccess({ roles }), AuthActions.getAllAvailableTenants()])
              : (actions = [AuthActions.fetchUserFeaturesSuccess({ roles })]);
            return actions;
          }),
          catchError((error: Error) => of(AuthActions.fetchUserFeaturesFail({ error })))
        )
      )
    )
  );

  fetchAllAvailableTenants$ = createEffect(() =>
    this.#actions$.pipe(
      ofType(AuthActions.getAllAvailableTenants),
      switchMap(() =>
        this.#auth.tenants().pipe(
          map((tenants) => AuthActions.getAllAvailableTenantsSuccess({ tenants })),
          catchError((error: Error) => of(AuthActions.getAllAvailableTenantsFail({ error })))
        )
      )
    )
  );
}
