import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Neighbours } from 'src/app/models/core/link.model';
import { Role } from 'src/app/models/core/role.model';




export const RolesActions = createActionGroup({
  source: 'Roles Actions',
  events: {
    RolesReset: emptyProps(),
    FetchRoles: emptyProps(),
    FetchRolesSuccess: props<{ roles: Array<Role> }>(),
    FetchRolesFail: props<{ error: Error }>(),
    GetRoleById: props<{ roleId: string }>(),
    GetRoleByIdSuccess: props<{ roleById: Role }>(),
    GetRoleByIdFail: props<{ error: Error  }>(),
    AddRole: props<{ role: Role }>(),
    AddRoleSuccess: props<{ role: Role }>(),
    AddRoleFail: props<{ error: Error }>(),
    DeleteRole: props<{ role: Role }>(),
    DeleteRoleSuccess: props<{ role: Role }>(),
    DeleteRoleFail: props<{ error: Error }>(),
    GetRolesNeighboursProperties: props<{ roleId: string }>(),
    GetRolesNeighboursPropertiesSuccess: props<{ roles: Neighbours }>(),
    GetRolesNeighboursPropertiesFail: props<{ error: Error }>(),
    GetRolesCompositeProperties: props<{roleId: string, tenant: string }>(),
    GetRolesCompositePropertiesSuccess: props<{neighbours: Neighbours }>(),
    GetRolesCompositePropertiesFail: props<{error: Error }>(),
    PostNodesLinkSuccess: props<{ keyNode: string; node: any  }>(),
    DeleteNodesLinkSuccess: props<{ keyNode: string, node: any  }>(),
    UpdateRole: props<{ name: string; role: Role }>(),
    UpdateRoleSuccess: props<{ name: string; role: Role }>(),
    UpdateRoleFail: props<{ error: Error }>(),
  },
});
