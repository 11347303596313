import { POWERBI_CONFIG } from '../app/configs/powerbi.config';

const company = {
  logo: './assets/img/logo.png',
  expandedLogo: './assets/img/rina-cube.png',
  logoBg: 'bgffd300',
  name: 'Admin Tool',
  role: '*',
};

const bypass_auth_token_resources = ['/assets/i18n/'];

const snackbar_config = {
  snackbar_duration: 2000,
  snackbar_error_duration: 10000,
};

export const globalEnvironment = {
  name: 'PROJECT_ADMIN-TOOL',
  production: false,
  company,
  date_format: 'MM-dd-yyyy HH:mm',
  first_available_date: '2021-01-01',
  supportUrl: 'https://support.cube.rina.org',
  bypass_auth_token_resources,
  snackbar_config,
};
