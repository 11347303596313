import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs';
import { selectUserModules } from 'src/app/store/selectors/auth.selectors';
import { AppState } from 'src/app/store/states/app.state';

@Injectable({ providedIn: 'root' })
export class ActiveService {
  modules!: Array<string>;
  #store: Store<AppState> = inject(Store<AppState>);
  #route: Router = inject(Router);

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const moduleName = next.data['moduleName'];
    return this.#store.select(selectUserModules).pipe(
      filter((activeModules) => activeModules !== undefined),
      map((activeModules) => {
        if (moduleName !== 'admin-tool.Manager') {
          let active = activeModules?.includes(moduleName);
          if (!active) {
            this.#route.navigate(['/users']);
          }
          return active;
        } else return true;
      })
    );
  }
}
export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(ActiveService).canActivate(next, state);
};
