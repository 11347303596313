import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { Tenant } from 'src/app/models/core/tenant.model';
import { User } from 'src/app/models/auth/admin-tool.type';
import { FlexLayoutModule } from '@angular/flex-layout';

@Component({
  selector: 'cube-user-menu',
  standalone: true,
  imports: [FlexLayoutModule, CommonModule, MatIconModule, MatListModule, MatMenuModule],
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent {
  @Input() username: string | null | undefined;
  @Input() user: User | null | undefined;
  @Input() availableTenants: Array<Tenant> | null | undefined;
  @Input() allAvailableTenants: Array<Tenant> | null | undefined;
  @Input() currentTenant: string | null | undefined;

  @Input() activeTenant: string | null | undefined;
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() support: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeCurrentTenant: EventEmitter<Tenant> = new EventEmitter<Tenant>();
  @Output() switchTheme: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeActiveTenant: EventEmitter<Tenant> = new EventEmitter<Tenant>();

  isOpen = false;

  constructor(private _reRef: ElementRef) {}

  @HostListener('document:keydown.escape', ['$event'])
  @HostListener('document:click', ['$event'])
  onClose(event: PointerEvent | KeyboardEvent) {
    if (!this.isOpen) return;
    if (event instanceof KeyboardEvent && event.key === 'Escape') this.isOpen = false;
    if (!this._reRef.nativeElement.contains(event.target)) this.isOpen = false;
  }
}
