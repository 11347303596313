@if(item){
<mat-nav-list [style.maxHeight]="item.open ? '1200px' : '48px'" class="primary">
  @if(item.link){
  <mat-list-item (click)="item.open = !item.open" [routerLink]="item.link" [matTooltip]="item.name" matTooltipPosition="right" routerLinkActive="active-menu-item">
    <ng-container *ngTemplateOutlet="iconTemplate; context: { $implicit: item }"></ng-container>
  </mat-list-item>
  }
</mat-nav-list>
}

<ng-template #iconTemplate let-item>
  <div class="text-white w-100 {{ iconOnly ? 'flex-center' : 'flex-start-center px-3' }}">
    @if(item.mat_icon){
    <mat-icon matListIcon style="font-size: 24px">{{ item.mat_icon }} </mat-icon>
    } @if(!iconOnly){
    <h3 matLine class="ps-3 mb-0">{{ item.name }}</h3>
    }
  </div>
</ng-template>
