import { b2cPolicies } from "./b2c-policies.dev";
import { globalEnvironment } from "./global";

export const environment = {
  ...globalEnvironment,
  api: {
    baseUrl: 'https://gw-dev.cube.rina.org/admin-tool',
    b2cUrl: 'https://gw-dev.cube.rina.org/auth',
  },
  storageKeys: {
    tenantInUse: 'current-tenant',
    theme: 'is-dark-theme',
  },
  companyTenant: { name: 'root' },
  production: false,
  b2cPolicies: {
    ...b2cPolicies,
    redirectUri: 'https://admin-dev.cube.rina.org/',
    postLogoutRedirectUri: 'https://www.rina.org/',
  },
  localStorageKeyPrefix: 'admin-tool-dev'
};