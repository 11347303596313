import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import * as screenfull from 'screenfull';

@Component({
  selector: 'cube-fullscreen',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  template: `
    <button mat-icon-button (click)="toggleFullscreen()">
      <mat-icon> {{ isFullscreen ? 'fullscreen_exit' : 'fullscreen' }} </mat-icon>
    </button>
  `,
})
export class FullscreenComponent {
  isFullscreen = false;
  screen = screenfull.default;

  toggleFullscreen() {
    if (this.screen.isEnabled) {
      this.screen.toggle();
      this.isFullscreen = !this.isFullscreen;
    }
  }
}
