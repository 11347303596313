import { Component, Input } from '@angular/core';
import { SideMenuItem } from '../models/sidemenu-item.interface';

@Component({
  selector: 'cube-sidemenu-item',
  templateUrl: './sidemenu-item.component.html',
  styleUrls: ['./sidemenu-item.component.scss']
})
export class SidemenuItemComponent {
  @Input() item: SideMenuItem | undefined;
  @Input() iconOnly = false;
}
