import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { environment } from 'src/environments/environment';
import { FullscreenComponent } from '../fullscreen/fullscreen.component';
import { UserMenuComponent } from '../user-menu/user-menu.component';
import { Tenant } from 'src/app/models/core/tenant.model';
import { User } from 'src/app/models/auth/admin-tool.type';

@Component({
  selector: 'cube-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatToolbarModule, FullscreenComponent, UserMenuComponent],
})
export class ToolbarComponent {
  @Input() showDrawer = false;
  @Input() searchResults: Array<unknown> = new Array<unknown>();
  @Input() activeTenant: string | null | undefined;
  @Input() currentTenant: string | null | undefined;

  @Input() user: User | null | undefined;
  @Input() availableTenants: Array<Tenant> | null | undefined;
  @Input() allAvailableTenants: Array<Tenant> | null | undefined;

  
  @Output() togglePanels: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleSidenav: EventEmitter<void> = new EventEmitter<void>();
  @Output() login: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeCurrentTenant: EventEmitter<Tenant> = new EventEmitter<Tenant>();
  @Output() changeActiveTenant: EventEmitter<Tenant> = new EventEmitter<Tenant>();
  @Output() switchTheme: EventEmitter<void> = new EventEmitter<void>();

  searchOpen = false;

  searchChanged(query: string): void {
    console.log(query);
  }

  searchOptionSelected(event: MatAutocompleteSelectedEvent): void {
    console.log(event);
  }

  openSupportPage(): void {
    window.open(environment.supportUrl, '_blank');
  }

  toggle(): void {
    if (this.showDrawer) {
      this.togglePanels.emit();
    } else {
      this.toggleSidenav.emit();
    }
  }
}
