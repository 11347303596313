import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { LanguageOption } from "src/app/models/core/language-option.type";
import { SystemNotification } from "src/app/models/core/system-notification.interface";
import { Theme } from "src/app/models/core/layout-state.model";

export const SystemActions = createActionGroup({
  source: 'System',
  events: {
    Redirect: props<{ url: string }>(),
    SetCurrentLang: props<{ currentLang: LanguageOption }>(),
    SetNotification: props<{ notification: SystemNotification | undefined }>(),
    SetTheme: props<{ theme: Theme }>(),
    StartLoading: emptyProps(),
    StopLoading: emptyProps(), 
  }
});