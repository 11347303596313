import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProfileFeature, User } from 'src/app/models/auth/admin-tool.type';
import { TokenDetails } from 'src/app/models/auth/token-details.type';
import { Feature, UserFeature } from 'src/app/models/core/feature.model';
import { Tenant } from 'src/app/models/core/tenant.model';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    RedirectToGateway: emptyProps(),
    RedirectToGatewaySuccess: emptyProps(),
    Login:  emptyProps(),
    LoginSuccess:props<{ tokenDetails: TokenDetails }>(),
    Logout: emptyProps(),
    SetActiveAccount: emptyProps(),
    SetTokenDetails: props<{ tokenDetails: TokenDetails }>(),
    FetchUserTenants: props<{ uniqueId: string }>(),
    FetchUserTenantsSuccess: props<{ tenants: Tenant[] }>(),
    FetchUserTenantsFail: props<{ error: Error }>(),
    UpdateTenantInUse: props<{ tenant: Tenant }>(),
    UpdateTenantSuccess: props<{ tenant: Tenant }>(),
    SwitchTenantInUse: props<{ tenant: Tenant }>(),
    ChangeActiveTenant: props<{ tenant: Tenant | undefined }>(),
    CanIUse: emptyProps(),
    FetchUserFeaturesSuccess: props<{ roles: UserFeature }>(),
    FetchUserFeaturesFail: props<{ error: Error }>(),
    ResetUserFeatures: emptyProps(),
    GetAllAvailableTenants: emptyProps(),
    GetAllAvailableTenantsSuccess: props<{ tenants: Tenant[] }>(),
    GetAllAvailableTenantsFail: props<{ error: Error }>(),
  },
});
