import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Asset } from 'src/app/models/core/asset.model';
import { Neighbours } from 'src/app/models/core/link.model';



export const AssetsActions = createActionGroup({
  source: 'Assets Actions',
  events: {
    AssetsReset: emptyProps(),
    FetchAssets: emptyProps(),
    FetchAssetsSuccess: props<{ assets: Array<Asset> }>(),
    FetchAssetsFail: props<{ error: Error }>(),
    AddAsset: props<{ asset: Asset }>(),
    AddAssetSuccess: props<{ asset: Asset }>(),
    AddAssetFail: props<{ error: Error }>(),
    UpdateAsset: props<{ assetName: string; asset: Asset }>(),
    UpdateAssetSuccess: props<{ assetName: string; asset: Asset }>(),
    UpdateAssetFail: props<{ error: Error }>(),
    GetAssetById: props<{ assetId: string }>(),
    GetAssetByIdSuccess: props<{ assetById: Asset }>(),
    GetAssetByIdFail: props<{ error: Error  }>(),
    DeleteAsset: props<{ asset: Asset }>(),
    DeleteAssetSuccess: props<{ asset: Asset }>(),
    DeleteAssetFail: props<{ error: Error }>(),
    GetAssetsCompositeProperties: props<{assetId: string, tenant: string }>(),
    GetAssetsCompositePropertiesSuccess: props<{neighbours: Neighbours }>(),
    GetAssetsCompositePropertiesFail: props<{error: Error }>(),
    GetAssetsNeighboursProperties: props<{ assetId: string }>(),
    GetAssetsNeighboursPropertiesSuccess: props<{ assets: Neighbours }>(),
    GetAssetsNeighboursPropertiesFail: props<{ error: Error }>(),
    PostNodesLinkSuccess: props<{ keyNode: string; node: any  }>(),
    DeleteNodesLinkSuccess: props<{ keyNode: string, node: any  }>()
  },
});
