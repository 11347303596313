import { Routes } from '@angular/router';
import { AuthGuard } from './providers/guards/auth.guard';

export const ROUTES: Routes = [


  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full',
  },
  {
    path: 'users',
    loadChildren: () => import('./features/users/users.module').then((m) => m.UsersModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Manager' },
  },
  {
    path: 'roles',
    loadChildren: () => import('./features/roles/roles.module').then((m) => m.RolesModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Manager' },
  },
  {
    path: 'tokens',
    loadChildren: () => import('./features/auth-tokens/auth-tokens.module').then((m) => m.AuthTokensModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Tokens' },
  },
  {
    path: 'userGroups',
    loadChildren: () => import('./features/user-groups/user-groups.module').then((m) => m.UserGroupsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Manager' },
  },
  {
    path: 'assetGroups',
    loadChildren: () => import('./features/asset-groups/asset-groups.module').then((m) => m.AssetGroupsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Manager' },
  },
  {
    path: 'assets',
    loadChildren: () => import('./features/assets/assets.module').then((m) => m.AssetsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Manager' },
  },
  {
    path: 'tenants',
    loadChildren: () => import('./features/tenants/tenants.module').then((m) => m.TenantsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Business' },
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./features/subscriptions/subscriptions.module').then((m) => m.SubscriptionsModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Business' },
  },
  {
    path: 'developers',
    loadChildren: () => import('./features/developers/developers.module').then((m) => m.DevelopersModule),
    runGuardsAndResolvers: 'always',
    canActivate: [AuthGuard],
    data: { moduleName: 'admin-tool.Developers' },
  },
  { path: '**', redirectTo: 'users' },
];
