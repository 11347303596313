/* eslint-disable @ngrx/no-typed-global-store */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
/* import { InteractionRequiredAuthError, RedirectRequest, SilentRequest } from '@azure/msal-browser';
 */ import { filter, map, Observable, switchMap } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AzureTokenInterceptor implements HttpInterceptor {
  readonly #authService: AuthenticationService = inject(AuthenticationService);
  //readonly #store: Store<AppState> = inject(Store);

  /* intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.resourceNeedsToken(req.url)) {
      const request = this.tokenRequest();
      let tokenDetails: TokenDetails | undefined = this.#authService.token.getValue();

      return of(tokenDetails as TokenDetails).pipe(
        map((accountDetails: TokenDetails) => ({
          isValidToken: this.isValidToken((accountDetails?.idTokenClaims as TokenDetailsAccountClaims)?.exp),
          idToken: accountDetails?.idToken || '',
          appendToken: this.resourceNeedsToken(req.url),
        })),
        switchMap(({ isValidToken, idToken, appendToken }) => {
          if ((!isValidToken && appendToken) || (appendToken && !idToken)) {
            return this.#authService.acquireTokenSilent(request).pipe(
              tap((response) => this.#authService.token.next(response as unknown as TokenDetails)),
              map(({ idToken }) => ({ idToken, appendToken }))
            );
          } else {
            return of({ idToken, appendToken });
          }
        }),
        switchMap(({ idToken, appendToken }) => {
          const clonedReq = this.cloneRequest(req, idToken, appendToken);
          return next.handle(clonedReq);
        }),
        catchError((err: HttpErrorResponse) => {
          if (err.status === HttpStatusCode.Unauthorized || err instanceof InteractionRequiredAuthError) {
            this.#authService.acquireTokenRedirect(request);
          }
          return throwError(() => err);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private isValidToken(expiration: number | undefined): boolean {
    return new Date(new Date().getTime() - 1000 * 300).valueOf() < (expiration || 1) * 1000;
  }

  private resourceNeedsToken(request_url: string): boolean {
    return environment.bypass_auth_token_resources.every((resource) => !request_url.includes(resource));
  }

  private tokenRequest(): SilentRequest | RedirectRequest {
    const account = this.#authService.getActiveAccount() ?? undefined;
    if (!account) {
      this.#authService.checkAndSetActiveAccount();
    }
    return {
      account,
      scopes: [],
    };
  }

  private cloneRequest(req: HttpRequest<unknown>, idToken: string, append_token: boolean): HttpRequest<unknown> {
    if (append_token) {
      const clonedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return clonedReq;
    } else {
      return req;
    }
  } */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return this.#authService.token.pipe(
        filter((token: string | undefined): token is string => !!token),
        map((token: string) => ({
          token,
          appendToken: this.#authService.resourceNeedsToken(req.url)
        })),
        switchMap(({ token, appendToken }) => {
          const clonedReq =
            token && appendToken
              ? req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${token}`
                  }
                })
              : req;
  
          return next.handle(clonedReq);
        })
      );
    }
}
