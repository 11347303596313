import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { SidemenuItemComponent } from "./sidemenu-item/sidemenu-item.component";
import { SidemenuComponent } from "./sidemenu.component";
import { UserHasModuleDirective } from "src/app/directives/user-has-module.directive";

@NgModule({
  declarations: [SidemenuComponent, SidemenuItemComponent],
  imports: [CommonModule, MatListModule, MatIconModule, MatChipsModule, MatTooltipModule, RouterModule, UserHasModuleDirective],
  exports: [SidemenuComponent]
})
export class SidemenuModule { }