/**
 * This module handles the integration with Azure AD B2C.
 *
 */

export class PKCE {
  /**
   * Proof Key for Code Exchange by OAuth Public Clients
   * https://datatracker.ietf.org/doc/html/rfc7636
   */

  // METHOD_PLAIN = 'plain';
  // METHOD_SHA = 'S256';

  constructor(verifier) {
    this.verifier = verifier;
  }

  getCodeVerifier() {
    return this.verifier;
  }

  getPlainCodeChallenge() {
    return this.verifier;
  }

  async getCodeChallenge() {
    const msgBuffer = new TextEncoder().encode(this.verifier);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    return PKCE.base64URLEncode(PKCE.arraybufferToString(hashBuffer));
  }

  static build() {
    return new this(this.buildVerifier());
  }

  static buildVerifier() {
    const length = 43 + Math.floor(Math.random() * (1 + 128 - 43));
    const array = new Uint8Array(30);
    const randomBytes = window.crypto.getRandomValues(array);
    const randomSymbols = this.base64URLEncode(randomBytes.toString());
    return randomSymbols.slice(0, length);
  }

  static base64URLEncode(str) {
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }

  static arraybufferToString(array) {
    const u8array = new Uint8Array(array);
    var t,
      output = '';
    for (var i of u8array) {
      t = i.toString(16);
      if (t.length < 2) t = '0' + t;
      output += '\\x' + t;
    }
    return eval("'" + output + "'");
  }

  static async verify(verifier, challenge, method) {
    if (method == 'plain') return verifier == challenge;
    else return (await new this(verifier).getCodeChallenge()) == challenge;
  }

  static async getKeys() {
    var obj = {},
      pkce = this.build();
    obj['codeVerifier'] = pkce.getCodeVerifier();
    try {
      obj['codeChallengeMethod'] = 'S256';
      obj['codeChallenge'] = await pkce.getCodeChallenge();
    } catch (e) {
      console.warn("[PKCE] Using 'plain' method.");
      obj['codeChallengeMethod'] = 'plain';
      obj['codeChallenge'] = pkce.getPlainCodeChallenge();
    } finally {
      return obj;
    }
  }
}

function mergeQuery(obj) {
  var query = '';
  for (var key of Object.keys(obj)) {
    query += key + '=' + encodeURIComponent(obj[key]) + '&';
  }
  return query;
}

export class ADB2CClient {
  constructor(tenant, clientId, policy) {
    this.tenant = tenant;
    this.clientId = clientId;
    this.policy = policy;
    this.baseUrl = 'https://test-login.rina.org/rinagroupb2cdevl.onmicrosoft.com/b2c_1a_signup_signin/oauth2/v2.0/';
    this.authAPI = this.baseUrl + 'authorize';
    this.tokenAPI = this.baseUrl + 'token';
  }

  getLogoutUrl(id_token, redirectUri) {
    return (
      this.baseUrl +
      'logout?' +
      mergeQuery({
        post_logout_redirect_uri: redirectUri,
        id_token_hint: id_token,
      })
    );
  }

  getLoginUrl(redirectUri, pkce, options) {
    return (
      this.authAPI +
      '?' +
      mergeQuery({
        client_id: this.clientId,
        redirect_uri: redirectUri,
        code_challenge: pkce.codeChallenge,
        code_challenge_method: pkce.codeChallengeMethod,
        response_type: options.responseType || 'code',
        response_mode: options.responseMode || 'fragment',
        scope: options.scope || 'openid',
        state: options.state,
      })
    );
  }

  async getTokenInfoByRefreshToken(refreshToken) {
    var r = await fetch(this.tokenAPI, {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: mergeQuery({
        client_id: this.clientId,
        scope: 'openid offline_access profile',
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      }),
    });
    return await r.json();
  }

  async getTokenInfoByCode(code, codeVerifier) {
    var r = await fetch(this.tokenAPI, {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: mergeQuery({
        client_id: this.clientId,
        scope: 'openid offline_access profile',
        grant_type: 'authorization_code',
        code: code,
        code_verifier: codeVerifier,
      }),
    });
    return await r.json();
  }
}
