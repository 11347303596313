import { createReducer, on } from '@ngrx/store';

import { AssetGroup } from 'src/app/models/core/asset-group.model';
import { Asset } from 'src/app/models/core/asset.model';
import { Role } from 'src/app/models/core/role.model';
import { UserGroup } from 'src/app/models/core/user-group';
import { ManagerState } from '../states/manager.state';
import { User } from 'src/app/features/users/models/user';
import { ManagerActions } from '../actions/manager.actions';
import { Feature, FeatureResponse } from 'src/app/models/core/feature.model';
import { Measure } from 'src/app/models/core/measure.model';

const INIT_STATE: ManagerState = {
  users: new Array<User>(),
  userGroups: new Array<UserGroup>(),
  assetGroups: new Array<AssetGroup>(),
  assets: new Array<Asset>(),
  roles: new Array<Role>(),
  features: new Array<FeatureResponse>(),
};

export const managerReducers = createReducer(
  INIT_STATE,
  on(ManagerActions.resetManagerState, () => ({ ...INIT_STATE })),
  on(ManagerActions.getNodePropertiesSuccess, (state: ManagerState, { response, configKey }): ManagerState => ({ ...state, [configKey]: response }))
);
