@if(featuresStore && featuresStore.length>0){
<div style="height: calc(100% - 64px)">
  <section class="sidemenu-container flex-between-stretch flex-column">
    <article class="flex-start flex-column sidemenu-item-list-container">
      @for(item of features; track $index){
      <cube-sidemenu-item *hasModule="item.module" [item]="item" [iconOnly]="iconOnly"></cube-sidemenu-item>
      }
    </article>
    <article class="flex-end-center flex-column" [style.margin]="'50px 0px 0px'">
      <a href="https://www.rina.org" target="_blank" alt="RINA.org" title="RINA S.P.A.">
        <img [width]="iconOnly ? 50 : 75" src="./assets/img/RINA-light.png" />
      </a>
      <div class="version">{{ version.version }}</div>
    </article>
  </section>
</div>
}
