import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VERSION } from 'src/environments/version';
import { SideMenuItem } from './models/sidemenu-item.interface';

@Component({
  selector: 'cube-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnChanges{
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['featuresStore'].currentValue){
      if(changes['featuresStore'].currentValue.length ===0){
        this.featuresStore =['admin-tool.Manager'];
      };
      
    }
  }
  @Input() iconOnly = false;
  @Input() isDarkTheme = false;
  @Input() features: SideMenuItem[] | null = [];
  @Input() featuresStore: Array<string>| null | undefined;

  version = VERSION;
}
