import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AssetGroup } from 'src/app/models/core/asset-group.model';
import { Neighbours } from 'src/app/models/core/link.model';



export const AssetGroupsActions = createActionGroup({
  source: 'Asset Groups Actions',
  events: {
    AssetGroupsReset: emptyProps(),
    FetchAssetGroups: emptyProps(),
    FetchAssetGroupsSuccess: props<{ assetGroups: Array<AssetGroup> }>(),
    FetchAssetGroupsFail: props<{ error: Error }>(),
    AddAssetGroup: props<{ assetGroup: AssetGroup }>(),
    AddAssetGroupSuccess: props<{ assetGroup: AssetGroup }>(),
    AddAssetGroupFail: props<{ error: Error }>(),
    UpdateAssetGroup: props<{ assetGroupName: string; assetGroup: AssetGroup }>(),
    UpdateAssetGroupSuccess: props<{ assetGroupName: string; assetGroup: AssetGroup }>(),
    UpdateAssetGroupFail: props<{ error: Error }>(),
    GetAssetGroupById: props<{ assetGroupId: string }>(),
    GetAssetGroupByIdSuccess: props<{ assetGroupById: AssetGroup }>(),
    GetAssetGroupByIdFail: props<{ error: Error  }>(),
    DeleteAssetGroup: props<{ assetGroup: AssetGroup }>(),
    DeleteAssetGroupSuccess: props<{ assetGroup: AssetGroup }>(),
    DeleteAssetGroupFail: props<{ error: Error }>(),
    GetAssetGroupsCompositeProperties: props<{assetGroupId: string, tenant: string }>(),
    GetAssetGroupsCompositePropertiesSuccess: props<{neighbours: Neighbours }>(),
    GetAssetGroupsCompositePropertiesFail: props<{error: Error }>(),
    GetAssetGroupsNeighboursProperties: props<{ assetGroupId: string }>(),
    GetAssetGroupsNeighboursPropertiesSuccess: props<{ assetGroups: Neighbours }>(),
    GetAssetGroupsNeighboursPropertiesFail: props<{ error: Error }>(),
    PostNodesLinkSuccess: props<{ keyNode: string; node: any  }>(),
    DeleteNodesLinkSuccess: props<{ keyNode: string, node: any  }>()
  },
});
