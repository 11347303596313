import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { SystemActions } from '../actions/system.actions';

@Injectable()
export class SystemEffects {
  readonly #actions$: Actions = inject(Actions);
  readonly #router: Router = inject(Router);

  redirect$ = createEffect(
    () => { 
      return this.#actions$.pipe(
        ofType(SystemActions.redirect),
        tap(({ url }) => this.#router.navigateByUrl(url))
      );
    }, { dispatch: false }
  );
}
