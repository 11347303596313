import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, Observable, tap, throwError } from 'rxjs';

import { MessageType } from 'src/app/models/core/system-notification.interface';
import { ResponseModel } from 'src/app/models/http/response-model.interface';
import { SystemActions } from 'src/app/store/actions/system.actions';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  readonly #store: Store = inject(Store);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap((response: HttpEvent<unknown>) => {
        const res: HttpResponse<ResponseModel<unknown>> = response as HttpResponse<ResponseModel<unknown>>;
    /*     if(res.status===201){
          this.#store.dispatch(SystemActions.setNotification({ notification: { type: MessageType.Info, message:  } }));

        } */
        if (res.body?.message) {
          this.#store.dispatch(SystemActions.setNotification({ notification: { type: MessageType.Info, message: res.body.message } }));
        }
      }),
      catchError((response: HttpErrorResponse) => {
        const message =
          req.url.includes('blob.core.windows.net') && response.status === HttpStatusCode.NotFound
            ? response.statusText
            : response.error.error;
        this.#store.dispatch(SystemActions.setNotification({ notification: { type: MessageType.Error, message } }));
        return throwError(() => response);
      })
    );
  }
}
