import { ADB2CClient } from './adB2c.js';
import { executeFlow, parseFragment, ADB2C } from './azuread.js';

const HASH_KEY_ADT_TOKEN = 'token';
const HASH_KEY_B2C_ID_TOKEN = 'id_token';

export const CubeAuth = {
  adtTokenEnabled: false,

  adb2cEnabled: false,
  adb2cClient: undefined,
  adb2cRedirectUri: undefined,

  adb2cCurrentIdToken: undefined,

  logoutRedirectUri: '',

  credentialsReadyFlag: true,

  onCredentialsReadyCallbacks: [],
  onADB2CNewTokenCallbacks: [],

  enableAdTTokens() {
    this.adtTokenEnabled = true;
  },

  enableADB2C(tenantId, clientId, policy, redirectUri) {
    this.adb2cEnabled = true;
    this.adb2cClient = new ADB2CClient(tenantId, clientId, policy);
    this.adb2cRedirectUri = redirectUri;
  },

  setLogutRedirectUri(uri) {
    this.logoutRedirectUri = uri;
  },

  set onCredentialsReady(f) {
    this.onCredentialsReadyCallbacks.push(f);
  },

  set onADB2CNewToken(f) {
    this.onADB2CNewTokenCallbacks.push(f);
  },

  _credentialsReady(authType, authToken) {
    console.debug('[CUBE AUTH] Credentials are ready.');
    if (this.credentialsReadyFlag === true) {
      this.credentialsReadyFlag = false;
      for (var callback of this.onCredentialsReadyCallbacks) callback(authType, authToken);
    }
  },

  _ADB2CNewToken(idToken, expires, profileInfo) {
    console.debug('[CUBE AUTH] New token available for AD B2C');
    this.adb2cCurrentIdToken = idToken;
    if (this.credentialsReadyFlag === true) this._credentialsReady('Bearer', idToken);
    for (var callback of this.onADB2CNewTokenCallbacks) callback(idToken, expires, profileInfo);
  },

  auth() {
    const q = parseFragment();

    if (this.adtTokenEnabled === true && HASH_KEY_ADT_TOKEN in q) {
      this._credentialsReady('Basic', q[HASH_KEY_ADT_TOKEN]);
    } else if (this.adb2cEnabled === true) {
      // Authentication flow
      executeFlow(this.adb2cClient, this.adb2cRedirectUri, CubeAuth._ADB2CNewToken.bind(this));
    } else throw new Error('No way to authenticate the user.');
  },

  async logout() {
    var redirectUrl = this.logoutRedirectUri;
    if (this.adb2cCurrentIdToken != undefined) {
      await ADB2C.persistence.clear();
      redirectUrl = this.adb2cClient.getLogoutUrl(this.adb2cCurrentIdToken, redirectUrl);
    }
    location.href = redirectUrl;
  },
};
