import { createSelector } from "@ngrx/store";
import { User } from "src/app/models/auth/admin-tool.type";
import { TokenDetails } from "src/app/models/auth/token-details.type";
import { AppState } from "../states/app.state";
import { AuthState } from "../states/auth.state";

const authState = (state: AppState): AuthState => state.auth;
export const selectTokenDetails = createSelector(authState, (state: AuthState): TokenDetails => state.tokenDetails);
export const selectUserDetails = createSelector(authState, (state: AuthState): User | undefined => state.user);
export const selectUsername =  createSelector(authState, (state: AuthState) => state.user.profile.username);
export const selectFirstAndSecondName =  createSelector(authState, (state: AuthState) => state.user.profile.first_name);
export const selectAllTenants = createSelector(authState, (state: AuthState) => state.tenants);
export const selectAllAvailableTenants = createSelector(authState, (state: AuthState) => state.allAvailableTenants);
export const selectUserModules = createSelector(authState, (state: AuthState) => state.roles?.modules);
export const selectActiveTenant = createSelector(authState, (state: AuthState) => state.activeTenant?.name);
export const selectCurrentTenant = createSelector(authState, (state: AuthState) => state.tenant.name);


export const selectCompanyTenant = () => createSelector(authState, (auth: AuthState) => {
    return {
      company: auth.tenant,
      activeTenant: auth.tenant
    };
  
});