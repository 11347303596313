import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LinkLabelsObject, LinkLabelsSubject } from 'src/app/models/core/link-labels';

export const ManagerActions = createActionGroup({
  source: 'Manager',
  events: {
    ResetManagerState: emptyProps(),
    PostNodesLink: props<{ linkLabel: string; subject: LinkLabelsSubject; object: LinkLabelsObject; tenant: string; keyNode: string; keyState: string; node: any }>(),
    PostNodesLinkSuccess: props<{ keyNode: string; keyState: string; node: any }>(), //cambiare unknown
    PostNodesLinkFail: props<{ error: Error }>(),
    GetSelectedBySolution: props<{ platform: string; solution: string; role: string; typeS: 'measureCategories' | 'feature' | string }>(),
    GetSelectedBySolutionSuccess: props<{ response: any }>(), //any da togliere
    GetSelectedBySolutionFail: props<{ error: Error }>(),
    DeleteNodesLink: props<{ linkLabel: string; subject: LinkLabelsSubject; object: LinkLabelsObject; tenant: string; keyNode: string; keyState: string; node: any }>(),
    DeleteNodesLinkSuccess: props<{ keyNode: string; keyState: string; node: any }>(), //unknown da modificare
    DeleteNodesLinkFail: props<{ error: Error }>(),
    GetNodeProperties: props<{ configKey: string, nodeId?: string }>(),
    GetNodePropertiesSuccess: props<{response: any, configKey: string }>(),
    GetNodePropertiesFail: props<{ error: Error }>(),
    GetTenantNodes: props<{ node: string}>(),
    GetTenantNodesSuccess: props<{ response: any}>(),
    GetTenantNodesFail: props<{ error: Error}>(),
    GetTenantResources: props<{ domain: Array<string>}>(),
    GetTenantResourcesSuccess: props<{ response: any}>(),
    GetTenantResourcesFail: props<{ error: Error}>(),
  /*   GetTenantFeatures */
    GetTenantFeaturesSuccess: props<{ response: any}>(),
    GetTenantFeaturesFail: props<{ error: Error}>(),
  },
});
