/* eslint-disable @ngrx/no-typed-global-store */
import { Directive, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { SystemActions } from '../store/actions/system.actions';
import { LanguageOption } from '../models/core/language-option.type';
import { selectCurrentLang } from '../store/selectors/system.selectors';
import { AppState } from '../store/states/app.state';

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[cubeTranslate]', standalone: true })
export class CubeTranslateDirective {
  readonly #translateService: TranslateService = inject(TranslateService);
  readonly #store: Store<AppState> = inject(Store<AppState>);
  constructor() {
    const browserLang = this.#translateService.getBrowserLang();
    const currentLang: LanguageOption = browserLang === 'it' ? 'it' : 'en';
    this.#store.dispatch(SystemActions.setCurrentLang({ currentLang }));

    this.#store
      .select(selectCurrentLang)
      .pipe(takeUntilDestroyed())
      .subscribe({
        next: (language: LanguageOption) => this.#translateService.use(language),
      });
  }
}
