import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { UserFeature } from 'src/app/models/core/feature.model';
import { Tenant } from 'src/app/models/core/tenant.model';
import { CubeAuth } from 'src/app/modules_msal/CubeAuth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
 // readonly #msalService: MsalService = inject(MsalService);
  readonly #http: HttpClient = inject(HttpClient);
  token: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  profile: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);


  whoAmI() {
    const params = new HttpParams().append('app', 'CUBE.admin-tool');
    return this.#http.get(`${environment.api.baseUrl}/WhoAmI`, { params }).pipe(
      catchError((error) => {
        return throwError(() => new Error(error));
      })
    );
  }

  canIuse(): Observable<UserFeature> {
    const features = ['adt-tokens-tenant', 'adt-tokens-user', 'auth-b2c-search', 'sys-active-tenant', 'adt-integration-administrator'];
    const modules = ['admin-tool.Manager', 'admin-tool.Tokens', 'admin-tool.Business', 'admin-tool.Developers'];
    return this.#http.post<UserFeature>(`${environment.api.baseUrl}/canIUse`, { features, modules });
  }

  login(){
       /*     this.msalService.loginRedirect();
     */
       CubeAuth.enableADB2C(
        environment.b2cPolicies.tenant,
        environment.b2cPolicies.clientId,
        environment.b2cPolicies.names.signUpSignIn,
        environment.b2cPolicies.redirectUri
      );
      CubeAuth.setLogutRedirectUri(environment.b2cPolicies.postLogoutRedirectUri);
  
      CubeAuth.onADB2CNewToken = (idToken: any, expires: any, profileInfo: any) => {
        this.token.next(idToken);
        this.profile.next(profileInfo.name);
      };
  
      CubeAuth.auth();
  }

  resourceNeedsToken(request_url: string): boolean {
    return environment.bypass_auth_token_resources.every((resource) => !request_url.includes(resource));
  }

  logout(){
    CubeAuth.logout();
    this.profile.next(undefined);
    this.token.next(undefined);
    //return this.#msalService.logoutRedirect();
  }

/*   getActiveAccount(): AccountInfo | null {
    return this.#msalService.instance.getActiveAccount();
  }

  acquireTokenSilent(request: SilentRequest): Observable<AuthenticationResult> {
    return this.#msalService.acquireTokenSilent(request);
  }

  acquireTokenRedirect(request: RedirectRequest): void {
    this.#msalService.acquireTokenRedirect(request);
  } */

/*   checkAndSetActiveAccount() {
    const activeAccount = this.getActiveAccount();
    const allAccounts = this.#msalService.instance.getAllAccounts();

    if (!activeAccount && allAccounts.length > 0) {
      this.#msalService.instance.setActiveAccount(allAccounts[0]);
    }
  } */

  tenants(): Observable<Array<Tenant>> {
    return this.#http.get<Array<Tenant>>(`${environment.api.baseUrl}/tenants`);
  }
  userTenants(uniqueId: string): Observable<Array<Tenant>> {
    return this.#http.get<Array<Tenant>>(`${environment.api.baseUrl}/users/${uniqueId}/solutions/CUBE/tenants`);
  }

  cloneRequest(req: HttpRequest<unknown>, idToken: string, append_token: boolean): HttpRequest<unknown> {
    if (append_token) {
      const clonedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${idToken}`,
        },
      });

      return clonedReq;
    } else {
      return req;
    }
  }
}
