import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Neighbours } from 'src/app/models/core/link.model';
import { UserGroup } from 'src/app/models/core/user-group';



export const UserGroupsActions = createActionGroup({
  source: 'User Groups Actions',
  events: {
    UserGroupsReset: emptyProps(),
    FetchUserGroups: emptyProps(),
    FetchUserGroupsSuccess: props<{ userGroups: Array<UserGroup> }>(),
    FetchUserGroupsFail: props<{ error: Error }>(),
    UpdateUserGroup: props<{ userGroupName: string; userGroup: UserGroup }>(),
    UpdateUserGroupSuccess: props<{ userGroupName: string; userGroup: UserGroup }>(),
    UpdateUserGroupFail: props<{ error: Error }>(),
    GetUserGroupById: props<{ userGroupId: string }>(),
    GetUserGroupByIdSuccess: props<{ userGroupById: UserGroup }>(),
    GetUserGroupByIdFail: props<{ error: Error  }>(),
    AddUserGroup: props<{ userGroup: UserGroup }>(),
    AddUserGroupSuccess: props<{ userGroup: UserGroup }>(),
    AddUserGroupFail: props<{ error: Error }>(),
    DeleteUserGroup: props<{ userGroup: UserGroup }>(),
    DeleteUserGroupSuccess: props<{ userGroup: UserGroup }>(),
    DeleteUserGroupFail: props<{ error: Error }>(),
    PostNodesLinkSuccess: props<{ keyNode: string; node: any  }>(),
    DeleteNodesLinkSuccess: props<{ keyNode: string, node: any  }>(),
    GetUserGroupsCompositeProperties: props<{userGroupId: string, tenant: string }>(),
    GetUserGroupsCompositePropertiesSuccess: props<{neighbours: Neighbours }>(),
    GetUserGroupsCompositePropertiesFail: props<{error: Error }>(),
    GetUserGroupsNeighboursProperties: props<{ userGroupId: string }>(),
    GetUserGroupsNeighboursPropertiesSuccess: props<{ userGroups: Neighbours }>(),
    GetUserGroupsNeighboursPropertiesFail: props<{ error: Error }>(),
  },
});
