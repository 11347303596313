export const VERSION = {
  "dirty": false,
  "raw": "v0.0.5-17-g58e690d",
  "hash": "g58e690d",
  "distance": 17,
  "tag": "v0.0.5",
  "semver": {
      "options": {
          "loose": false,
          "includePrerelease": false
      },
      "loose": false,
      "raw": "v0.0.5",
      "major": 0,
      "minor": 0,
      "patch": 5,
      "prerelease": [],
      "build": [],
      "version": "3.0.0"
  },
  "suffix": "17-g58e690d",
  "semverString": "0.0.5+17.g58e690d",
  "version": "3.0.0"
};