import { SideMenuItem } from '../models/sidemenu-item.interface';

export const SIDE_MENU: Array<SideMenuItem> = [
  {
    id: 'users',
    name: 'Users',
    mat_icon: 'person',
    link: 'users',
    module:'*',
    open: false,
  },
  {
    id: 'userGroups',
    name: 'User Groups',
    mat_icon: 'group',
    link: 'userGroups',
    module: '*',
    open: false,
  },
  {
    id: 'assets',
    name: 'Assets',
    mat_icon: 'all_out',
    link: 'assets',
    module: '*',
    open: false,
  },
  {
    id: 'assetGroups',
    name: 'Asset Groups',
    mat_icon: 'donut_small',
    link: 'assetGroups',
    module: '*',
    open: false,
  },
  {
    id: 'roles',
    name: 'Roles',
    mat_icon: 'admin_panel_settings',
    link: 'roles',
    module:'*',
    open: false,
  },

  {
    id: 'tokens',
    name: 'Tokens',
    mat_icon: 'lock',
    link: 'tokens',
    module: 'admin-tool.Tokens',
    open: false,
  },
  {
    id: 'tenants',
    name: 'Tenants',
    mat_icon: 'language',
    link: 'tenants',
    module: 'admin-tool.Business',
    open: false,
  },
  {
    id: 'subscriptions',
    name: 'Subscriptions',
    mat_icon: 'subscriptions',
    link: 'subscriptions',
    module: 'admin-tool.Business',
    open: false,
  },
  {
    id: 'developers',
    name: 'Developers',
    mat_icon: 'developer_mode',
    link: 'developers',
    module: 'admin-tool.Developers',
    open: false,
  },

];
